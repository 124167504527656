import React from "react"
import styled from 'styled-components'


const Footer = ({ className, children, siteTitle }) => (
  <footer className={className}>
    <div className="container">
      <div className="row">
        <div className="col">
          <FooterContent>
            © {new Date().getFullYear()}, Oceanit
          </FooterContent>
        </div>
      </div>
    </div>
    
  </footer>
)


const StyledFooter = styled(Footer)`
  background-color: #F6F9FC;
  width: 100%;
  padding: 16px 0;
  margin-top: 6rem;
  border-top: solid 1px rgba(29,29,31,0.1);
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #525f7f;
`
const FooterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export default StyledFooter
