import React from 'react';
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    black: "#212529",
    darkBlue: "#525f7f",
    darkPurple: "#404452",
    lightGreen: "#68DE79",
    white: "ffffff"
  },
  fonts: {
    main: "'Open Sans', sans-serif",
  }
}


const Theme = ( {children } ) => (
  <ThemeProvider theme={theme}> {children}</ThemeProvider>
);

export default Theme;