import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled, {css} from 'styled-components'
import oceanitLogo from "../images/oceanit_logo_color_horizontal.svg"
import externalLink from "../images/externalLink.svg"




const Header = ({ className, children, siteTitle }) => (
  <header className={className}>
    <div className="container">
      <div className="row">
        <div className="col">
          <Container>
            <a href="/">
              <StyledLogo src={oceanitLogo} />
            </a>

            <StyledH1 >
              <Link to="/">
              </Link>
            </StyledH1>
            
            <NavLinks>
              <Button href="/news"> News  </Button>
              <Button href="http://oceanit.com"> Oceanit.com  <StyledExternalLink src={externalLink} /> </Button>
            </NavLinks>
            
          </Container>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const StyledLogo = styled.img`
  height: 32px;
  margin: 16px;
  margin-left: 0px;
`

const NavLinks = styled.div`
  display: flex;
`

const StyledExternalLink = styled.img`
  height: 20px;
  margin: 0;
  /*transform: translateY(4px)*/
`

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const StyledHeader = styled(Header)`
  backdrop-filter: saturate(180%) blur(7px);
  background-color: rgba(255,255,255,0.7);
  margin-bottom: 0;
  position: sticky;
  top: -1px;
  //crop line height
  display: flex;
  align-content: space-between;
  justify-content: center;
  z-index: 999;
  &::before {
    margin-top: -10px;
    content: "";
  }


  //this is the bottom gray border, the way apple does it
  &::after {
    left: 0;
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    width: 100%;
    height: 1px;
    z-index: 1;
    background-color: rgba(29,29,31,0.1);
  }
`

const Button = styled.a`
  background: transparent;
  text-decoration: none;
  border-radius: 3px;
  border: none;
  margin: 0 1em;
  margin-right: 0px;
  margin-left: 1rem;
  padding: 0.25em 1em;
  padding-right: 0px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  color: #525f7f;
  cursor: pointer;
  
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    opacity: 0.8;
    transition-duration: 0s;
    transition-property: opacity;
    color: #525f7f;
    text-decoration: none;
  }


  ${props => 
    props.primary && 
    css`
      background: palevioletred;
      color: white;
    `
  }
`

const StyledH1 = styled.h1`
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 28px;
  color: #32325d;
  a{
    color: #32325d;
    text-decoration: none;
  }
`

export default StyledHeader
